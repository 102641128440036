<template>
  <div>
    <section class="section-watch grid-watch" id="warranty">
      <!-- LEFT -->
      <div class="watch-content watch-left">
        <div class="jbtn-watch-link">
          <!-- {{ $C.MODELS_INDEX[watch.model] }} -->
          <img
            id="watch-model-logo"
            src="@/assets/models/first-edition/logo/first-edition-white.svg"
            alt=""
          />
        </div>
        <router-link :to="{ name: 'Certificate', params: { id: watchId } }" class="jbtn jbtn-watch-nav">
          Info
        </router-link>
        <router-link :to="{ name: 'ServicesList', params: { watchId: watchId } }" class="jbtn jbtn-watch-nav">
          Servicing
        </router-link>
        <router-link :to=" { name: 'ServiceHistory', params: { id: watchId } }" class="jbtn jbtn-watch-nav">Service History</router-link>
        <router-link :to=" { name: 'PaymentsWatch', params: { id: watchId } }" class="jbtn jbtn-watch-nav">Payments</router-link>
        <router-link :to=" { name: 'Tutorials', params: { id: watchId } }" class="jbtn jbtn-watch-nav active">Tutorials</router-link>
        <router-link :to="{ name: 'Warranty', params: { id: watchId } }" class="jbtn jbtn-watch-nav"
          >Warranty</router-link
        >

        <div v-if="watch.serial" class="mywatch-content jbtn-watch-link watch-right-serial">
          <small>SERIAL</small><br />
          {{ watch.serial }}
        </div>
      </div>

      <!-- RIGHT -->
      <div class="watch-content watch-right">
        <div class="grid-2">
          <div class="jcard-duke jcard-news">
            <div class="news-cover-image"></div>
            <div class="news-content">
              <h5>
                Movement
                <span class="jbtn-text text-gold ml-2">
                  <i class="fal fa-play"></i>
                </span>
              </h5>
              <p class="m-0">
                <small>
                  TUTORIAL |
                  2:33</small
                >
              </p>

              <p class="m-0 mt-3">Learn how to use your DUKE watch.</p>
            </div>
          </div>
          <div class="jcard-duke jcard-news">
            <div class="news-cover-image"></div>
            <div class="news-content">
              <h5>
                Case
                <span class="jbtn-text text-gold ml-2">
                  <i class="fal fa-play"></i>
                </span>
              </h5>
              <p class="m-0">
                <small>
                  TUTORIAL |
                  2:33</small
                >
              </p>

              <p class="m-0 mt-3">Learn how to use your DUKE watch.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// import toast from "@/assets/js/toast";
import firebase from "@/firebase/firebaseInit";
import C from "@/const";
const db = firebase.db;
const auth = firebase.auth;

export default {
  name: "MyWatch",
  data() {
    return {
      isLoading: false,
      userId: "",
      watchId: "",
      modelId: "",
      model: {
        id: "",
        name: "First Edition",
        assets: {
          front: ""
        }
      }
    };
  },
  methods: {
    getWatch: function(watchId) {
      db.collection("watches")
        .doc(watchId)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            const docdata = doc.data();
            docdata.id = doc.id;
            this.watches.push(docdata);

            //load watch model
            this.modelId = doc.data().modelId;
            this.getWatchModel();
          });
        })
        .catch(error => {
          console.log("Error getting watch: ", error);
        });
    },
    // LOAD watch model
    getWatchModel: function() {
      db.collection(C.COLLECTION.WATCH_CATALOG)
        .doc(this.modelId)
        .get()
        .then(doc => {
          const docdata = doc.data();
          docdata.id = doc.id;
          this.model = docdata;
        })
        .catch(error => {
          console.log("Error getting WATCH MODEL document: ", error);
        });
    }
  },
  computed: {
    user: {
      get() {
        return this.$store.getters.getUser;
      }
    },
    watch: {
      get() {
        return this.$store.getters.getWatch;
      }
    }
  },
  created() {
    //init
    this.userId = auth.currentUser.uid;

    //get id from params
    if (this.$route.params.id != null && this.$route.params.id != "") {
      this.watchId = this.$route.params.id;
    }

    //load watch
    var watchRef = db.collection("watches").doc(this.watchId);
    watchRef.onSnapshot(doc => {
      this.$store.commit("setWatch", doc.data());
      //   this.userId = doc.data().userId;
    });
    //load user
    var userDocRef = db.collection("users").doc(this.userId);
    userDocRef
      .get()
      .then(userDoc => {
        this.$store.commit("setUser", userDoc.data());
      })
      .catch(function(error) {
        console.log("Error getting USER document:", error);
      });
  }
};
</script>

<style scoped></style>
